import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				BlastZone
			</title>
			<meta name={"description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:title"} content={"BlastZone"} />
			<meta property={"og:description"} content={"Відчуйте неймовірний адреналін!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b0b2fdcae00021e34031/images/1-4.jpg?v=2024-06-12T07:08:52.451Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Marque>
			<Override slot="text1" />
			<Override slot="text" />
		</Components.Marque>
		<Section padding="80px 0 80px 0" background="--color-dark" color="--lightD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--light">
					Приєднуйтесь до руху BlastZone!
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--lightD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Не чекайте – відчуйте гострі відчуття пейнтболу в BlastZone вже сьогодні! Незалежно від того, чи хочете ви весело провести день з друзями, корпоративну командоутворення чи змагання, BlastZone стане вашим головним місцем. Нехай починаються битви!
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							Vul. Likarsʹka, 2, Bryukhovychi,
							<br />
							{" "}Lviv Oblast, 79044
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="0965357576"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							0965357576
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="info@sparkivia.com"
							color="--light"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@sparkivia.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3" />
			<Override slot="link3" />
			<Override slot="link4" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});